import React from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { ApplicationFragment, PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"

import StreamingEconomicsProductSelector from "components/streaming-economics-product-selector"
import Divider from "components/divider"
import ProductPageHeader from "components/product-page-header"
import StreamingEconomicsProductsHeader from "components/streaming-economics-products-header"
import SlantedSection from "components/sections/slant"
import Demand360Lite from "components/demand360-lite"
import StyledSlantedSection from "components/sections/styled-slant"
import Demand360AddonModule from "components/sections/demand360-addon-module"
import BgDots from "images/bg/demand360-product.jpg"
import { getSharePreviewUrls } from "utils/share-preview"
import ProductVideoSection from "components/sections/product-video-section"
import FeaturedContent from "components/sections/featured-content"
import FeaturedPressCarousel from "components/featured-press-carousel"
import PartnerLogos from "components/partner-logos"
import ExploreOtherProducts from "components/sections/explore-other-products"
import IndustryBubbles from "../components/sections/industry-bubbles"
import FeaturedVideo from "components/sections/featured-video"

const Main = styled.div`
  .audience-insights-wrapper {
    ${tw`mx-auto max-w-s m:max-w-m l:max-w-l mb-20 m:mb-10`}

    &.extra-content {
      ${tw`m:mb-0`}
    }
  }
`

const Container = styled.div`
  ${tw`mx-auto relative`}

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    ${({ bg }) => `url(${bg})`};
  background-repeat: repeat-y;
  background-position: center;
  background-size: 100%;
  background-color: black;
`

const ProductFeatureStyles = styled.div`
  ${tw`text-white text-left flex flex-col-reverse l:flex-row odd:l:flex-row-reverse`}

  &.odd {
    .info {
      ${tw`l:pr-0 l:pl-10`}
    }
  }

  .info {
    ${tw`w-full l:w-1/2 mt-6 l:mt-0 l:pr-10`}

    .title {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;

      @media (min-width: 640px) {
        font-size: 44px;
        line-height: 55px;
      }
    }

    .body {
      ${tw`mt-5`}

      font-size: 18px;
      line-height: 30px;
    }
  }
`

function ProductFeature({ feature, index }) {
  const { featuredImage, headerContent, mainContent } = feature
  const odd = (index + 1) % 2 !== 0

  return (
    <ProductFeatureStyles className={odd ? "odd" : ""}>
      <div className="info">
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: `<div>${headerContent.html}</div>`,
          }}
        />
        <div
          className="body"
          dangerouslySetInnerHTML={{ __html: `<div>${mainContent.html}</div>` }}
        />
      </div>
      {/* <div className="hidden l:block l:w-1/12" /> */}
      <div className="w-full l:w-1/2 flex flex-col rounded">
        <img src={featuredImage.url} className="rounded object-cover" />
      </div>
    </ProductFeatureStyles>
  )
}

const StreamingEconomicsProduct = ({ data }) => {
  const { gcms } = data
  const { page } = data.gcms
  const { contentBlocks } = page
    const { demand360 } = gcms

  const features = contentBlocks.filter(({ identifier }) => !identifier)
  const videoSection = contentBlocks.find(
    ({ identifier }) => identifier === "video-section-block"
  )

    const { featuredPressArticles, featuredLogosClients, featuredVideo } =
        demand360

    const extraContentBlocks = contentBlocks.filter(({ identifier }) =>
    identifier?.endsWith("-extra-content")
  )

    const hasExtraCbs = extraContentBlocks.length > 0


    return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <StreamingEconomicsProductsHeader current={page.path} />
      <ProductPageHeader page={page} />
      <StreamingEconomicsProductSelector current={page.path} />
      <Main className="text-white">
        <div className="text-center overflow-visible" style={{ height: "2px" }}>
          <Divider />
        </div>
        <Container bg={BgDots}>
          <div className="pt-16 m:pt-48 px-2 flex flex-wrap justify-start items-start mx-auto max-w-s m:max-w-m l:max-w-l space-y-40 pb-24 l:pb-28 relative z-10">
            {features.map((feature, index) => (
              <ProductFeature
                feature={feature}
                index={index}
                key={feature.id}
              />
            ))}
          </div>


            {videoSection && (
                <ProductVideoSection
                    className={`${hasExtraCbs ? "mb-20" : "mb-40 m:mb-48"}`}
                    contentBlock={videoSection}
                />
            )}


            {hasExtraCbs && (
                <div className="mb-24">
                    {extraContentBlocks.map((cb) => (
                        <FeaturedContent cb={cb} className="on-list" key={cb.id} />
                    ))}
                </div>
            )}

            {featuredLogosClients && (
                <div className="mx-auto max-w-s m:max-w-m l:max-w-l mt-24 l:mt-20">
                    <PartnerLogos data={featuredLogosClients} />
                </div>
            )}

            <div className="mx-auto max-w-s m:max-w-m l:max-w-l">
                <ExploreOtherProducts
                    className="mt-5"
                    page={page.path.split("/").pop()}
                />
            </div>
            <div className="py-32 m:pt-48 px-2 flex flex-col justify-around items-center content-center mx-auto max-w-s m:max-w-m l:max-w-l min-h-screen">
                <IndustryBubbles />
            </div>

            {featuredVideo && (
                <div className="mt-12">
                    <FeaturedVideo video={featuredVideo} />
                </div>
            )}

            {featuredPressArticles?.length > 0 && (
                <SlantedSection className="pt-0 l:pt-14 z-10 pb-40">
                    <FeaturedPressCarousel
                        className="dark"
                        articles={featuredPressArticles}
                    />
                </SlantedSection>
            )}

        </Container>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query StreamingEconomicsProductPage($pagePath: String) {
    gcms {
      page(where: { path: $pagePath }) {
        ...PageData
        headerImg: headerImage {
             url(transformation: { image: { resize: { width: 1200 } } })
        }
        sortedBlocks: contentBlocks {
          id
          title
          identifier
          headerContent {
            html
            text
          }
          mainContent {
            html
          }
          featuredImage {
            mimeType
            handle
            url
          }
          extraData
          solutionsProduct {
            description
            features
          }
          linkUrl
          label
          linkTargetBlank
          callToAction
          youtubeId
        }
        insightsArticleSerieses {
          identifier
          insightsArticles {
            ...InsightsData
            pressLogo {
              url(
                transformation: {
                  image: { resize: { width: 250, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
          }
        }
      }
      demand360: page(where: { path: "/products/demand360" }) {
        ...PageData
      }
    }
  } 
`

export default StreamingEconomicsProduct
